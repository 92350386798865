import React from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'reducers';
import { AppointmentServiceType } from 'types/types';
import { Field } from 'formik';
import { FormikSelectField } from 'components/forms/FormikSelectField';
import { getCaseAppTypes } from 'selectors/cases/getCaseAppointmentServiceTypes';

export const fieldName = 'appointmentServiceType';

interface OwnProps {
  selectedCaseId: number;
}

interface StateProps {
  appointmentServiceTypes: AppointmentServiceType[];
}

type Props = StateProps & OwnProps;

const mapStateToProps = (
  state: GlobalState,
  { selectedCaseId }: OwnProps
): StateProps => ({
  appointmentServiceTypes: getCaseAppTypes(selectedCaseId)(state) || [],
});

const AppointmentServiceTypeSelectorComponent = ({
  appointmentServiceTypes,
}: Props) => {
  if (appointmentServiceTypes.length) {
    return (
      <Field
        name={fieldName}
        component={FormikSelectField}
        options={appointmentServiceTypes.map(ast => ({
          label: ast.name,
          value: ast,
        }))}
      />
    );
  }

  return <p>No appoinment types available</p>;
};

export const AppointmentServiceTypeSelector = connect(mapStateToProps)(
  AppointmentServiceTypeSelectorComponent
);
