import React, { ReactNode } from 'react';
import { Box, BoxProps, Flex } from 'rebass';
import { FullOverlay } from 'components/FullOverlay/FullOverlay';

export enum DropdownPosition {
  BottomRight,
  BottomLeft,
}

export interface Props {
  isOpen: boolean;
  trigger: ReactNode;
  children: ReactNode;
  style?: BoxProps['style'];
  position?: DropdownPosition;
  onClose?: () => void;
}

const getRawPosition = ({ position }: { position: DropdownPosition }) => {
  switch (position) {
    case DropdownPosition.BottomLeft:
      return {
        right: 0,
      };
    case DropdownPosition.BottomRight:
      return {
        left: 0,
      };
  }
};

export const Dropdown = ({
  isOpen,
  trigger,
  children,
  position = DropdownPosition.BottomRight,
  onClose = () => {},
}: Props) => {
  return (
    <>
      {isOpen && (
        <FullOverlay backgroundColor="rgb(0, 0, 0, 0.5)" onClick={onClose} />
      )}
      <Box
        style={{
          position: 'relative',
        }}
      >
        <Box>{trigger}</Box>
        {isOpen && (
          <Flex
            sx={{
              backgroundColor: 'white',
              position: 'absolute',
              zIndex: 999,
              borderRadius: 5,
              border: '1px solid',
              borderColor: 'muted',
              ...getRawPosition({ position }),
            }}
          >
            {children}
          </Flex>
        )}
      </Box>
    </>
  );
};
