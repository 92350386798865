import React, { SyntheticEvent } from 'react';
import { Button, Flex, Text } from 'rebass';
import { Footer } from 'components/Footer';
import ReactLoader from 'react-loader-spinner';
import { useConnectionStatus } from 'hooks/useConnectionStatus';

export interface Props {
  onClick?: (e: SyntheticEvent) => void;
  label: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  isSubmit?: boolean;
  variant?: string;
  children?: React.ReactNode;
}

export const FooterButton = ({
  onClick,
  label,
  disabled = false,
  loading = false,
  isSubmit = false,
  variant,
  children = null,
}: Props) => {
  const { isOnline } = useConnectionStatus();

  return (
    <Flex flexDirection="column">
      {!isOnline ? (
        <Text
          color="white"
          backgroundColor="red"
          textAlign="center"
          fontSize={1}
          padding={1}
        >
          You're offline or your connection is unstable.
        </Text>
      ) : null}
      <Footer
        flexDirection="column"
        sx={{
          zIndex: 10,
          position: 'relative',
          background: 'white',
        }}
      >
        {children}
        <Button
          width="80%"
          onClick={onClick}
          style={Object.assign(
            loading && variant !== 'secondary' ? { padding: 10 } : {},
            { height: 44 }
          )}
          disabled={!isOnline || disabled || loading}
          {...(isSubmit ? { type: 'submit' } : {})}
          {...(variant ? { variant } : {})}
        >
          {loading && isOnline ? (
            <ReactLoader
              type="Oval"
              color={variant === 'secondary' ? 'gray' : 'white'}
              height={25}
              width={25}
            />
          ) : (
            <>{!isOnline ? '(Offline) ' : label}</>
          )}
        </Button>
      </Footer>
    </Flex>
  );
};
