import { OFFERS_PATH } from 'routing/routePaths';

export const getAppointmentPath = ({
  appointmentId,
}: {
  appointmentId: number | string;
}) => `/schedule/${appointmentId}`;

export const getPaymentPath = ({
  appointmentId,
}: {
  appointmentId: number | string;
}) => `/payment/${appointmentId}`;

export const getCreditCardInputPath = ({
  appointmentId,
}: {
  appointmentId: number | string;
}) => `/payment/${appointmentId}/newCard`;

export const getPaymentReviewPath = ({
  appointmentId,
}: {
  appointmentId: number | string;
}) => `/review/${appointmentId}`;

export const getPaymentSuccessPath = ({
  appointmentId,
}: {
  appointmentId: number | string;
}) => `/paymentSuccess/${appointmentId}`;

export const getAddressUpdatePath = ({
  patientId,
}: {
  patientId: number | string;
}) => `/address-update/${patientId}`;

export const getAddressInputPath = ({
  patientId,
}: {
  patientId: number | string;
}) => `/address-input/${patientId}`;

export const getPostSessionPath = ({
  appointmentId,
}: {
  appointmentId: number | string;
}) => `/post-session/${appointmentId}`;

export const getReschedulePath = ({
  appointmentId,
}: {
  appointmentId: number | string;
}) => `/schedule/${appointmentId}/reschedule`;

export const getRescheduleSuccessPath = ({
  appointmentId,
}: {
  appointmentId: number | string;
}) => `${getReschedulePath({ appointmentId })}/success`;

export const getCancellationPath = ({
  appointmentId,
}: {
  appointmentId: number | string;
}) => `/schedule/${appointmentId}/cancel`;

export interface CarePlanUrlParams {
  patientId: string;
}
export const getCarePlanPath = ({ patientId }: CarePlanUrlParams) =>
  `/patient/${patientId}/care-plan`;

export interface OfferUrlParams {
  offerId: string;
}
export const getOfferPath = ({ offerId }: OfferUrlParams) =>
  `${OFFERS_PATH}/${offerId}`;

export interface AppointmentUpdateUrlParams {
  appointmentId: string;
}
export const getAppointmentUpdateUrl = ({
  appointmentId,
}: AppointmentUpdateUrlParams) => `/schedule/${appointmentId}/update`;
