import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Flex, Text } from 'rebass';
import { HeaderBar } from 'components/HeaderBar';
import { AppointmentDate } from 'components/AppointmentDate/AppointmentDate';
import { History } from 'history';
import { match } from 'react-router-dom';
import { ArrowBackIcon } from 'components/icons/ArrowBackIcon';
import { Appointment, Case, Insurance, Lead, Patient } from 'types/types';
import { PatientInfo } from 'components/PatientInfo/PatientInfo';
import { AppointmentDurationLocationInfo } from 'components/AppointmentDurationLocationInfo/AppointmentDurationLocationInfo';
import { FieldDisplayer } from 'components/FieldDisplayer/FieldDisplayer';
import { CustomTextLink } from 'components/CustomLink/CustomTextLink';
import { Map } from 'components/Map/Map';
import { MapPinIcon } from 'components/icons/MapPinIcon';
import { Marker } from 'google-maps-react';
import { getGoogleMapsDirectionsUrl } from 'utils/getGoogleMapsDirectionsUrl';
import { AppointmentPathParams } from 'routing/routePaths';
import { connect, useDispatch, useSelector } from 'react-redux';
import { startSession as startSessionAction } from 'modules/appointments/actions';
import { ScrollBox } from 'components/ScrollBox/ScrollBox';
import { CallButton } from 'components/CallButton/CallButton';
import { getAppointmentCaseProgressionFraction } from 'utils/case/caseProgression/getAppointmentCaseProgressionFraction';
import {
  getAppointmentUpdateUrl,
  getCancellationPath,
  getCarePlanPath,
  getReschedulePath,
} from 'routing/routeFunctions';
import { ConnectedAppointmentInfo } from 'containers/ConnectedAppointmentInfo/ConnectedAppointmentInfo';
import { formatCurrency } from 'utils/formatCurrency';
import { ACTION_HOOKS, CallAction } from 'utils/hooks/useCall/config';
import { CallError } from 'components/CallError/CallError';
import {
  CLEAR_EVAL_SESSION_STATE,
  getEvalSessionState,
  START_EVAL_SESSION_WITH_ERRORS,
} from 'reducers/evalSession';
import { getIsFetchingSessionData } from 'selectors/session/getIsFetchingSessionData';
import { getIsMissingDocsNoticesEnabled } from 'selectors/features/getIsMissingDocsNoticesEnabled';
import { RisePopup } from 'components/RisePopup';
import { HOME_HEALTH_APPOINTMENT_CANCEL } from 'modules/appointments/actionTypes';
import { getIsHomeHealthAlertEnabled } from 'selectors/features/getIsHomeHealthAlertEnabled';
import { AppointmentArrivalButton } from 'pages/appointment/AppointmentArrivalButton';
import moment from 'moment';
import { HomeHealthWarningCheck } from 'pages/appointment/HomeHealthWarningCheck';
import { REQUIRED_DOCUMENTS } from 'pages/appointment/constants';
import { MissingDocumentHandler } from 'pages/appointment/MissingDocumentHandler';
import { MenuIcon } from 'components/icons/MenuIcon';
import { DropdownPosition } from 'components/Dropdown/Dropdown';
import { DropdownMenu } from 'components/DropdownMenu/DropdownMenu';
import { getIsAppointmentRescheduleEnabled } from 'selectors/features/getIsAppointmentRescheduleEnabled';

interface OwnProps {
  match: match<AppointmentPathParams>;
  history: History;
  appointment?: Appointment;
  lead?: Lead;
  patient?: Patient;
  insurance?: Insurance;
  therapyCase?: Case;
}

interface DispatchProps {
  startSession: typeof startSessionAction;
}

type Props = OwnProps & DispatchProps;

const mapDispatchToProps: DispatchProps = {
  startSession: startSessionAction,
};

const ScheduledAppointmentPageComponent = ({
  history,
  appointment,
  lead,
  patient,
  insurance,
  therapyCase,
}: Props) => {
  const dispatch = useDispatch<any>();
  const [hasAskedForHomeTreatment, setHasAskedForHomeTreatment] = useState(
    false
  );
  const [showHomeTreatmentAlert, setShowHomeTreatmentAlert] = useState(false);
  const goBack = useCallback(() => history.goBack(), [history]);

  const { call: startSession, fetchStatus } = ACTION_HOOKS[
    CallAction.StartSession
  ]({});

  const { isStartingSessionWithErrors, isSkippingStartErrors } = useSelector(
    getEvalSessionState
  );

  const isHomeHealthAlertEnabled = useSelector(getIsHomeHealthAlertEnabled);
  const isFetchingSessionData = useSelector(getIsFetchingSessionData);
  const isMissingDocsNoticesEnabled = useSelector(
    getIsMissingDocsNoticesEnabled
  );
  const isAppointmentRescheduleEnabled = useSelector(
    getIsAppointmentRescheduleEnabled
  );

  const startTime = useMemo(() => moment(appointment?.appDatetime), [
    appointment,
  ]);

  const isMissingRequiredDocs = !!lead?.missingDocs?.some(doc =>
    REQUIRED_DOCUMENTS.includes(doc)
  );

  const isStartSessionDisabled =
    isStartingSessionWithErrors && !isSkippingStartErrors;

  const startThisSession = useCallback(() => {
    if (
      isHomeHealthAlertEnabled &&
      insurance?.insType === 'Medicare' &&
      !hasAskedForHomeTreatment
    ) {
      setShowHomeTreatmentAlert(true);
      setHasAskedForHomeTreatment(true);
      return;
    }
    if (appointment) {
      if (isMissingDocsNoticesEnabled) {
        if (isMissingRequiredDocs && !isSkippingStartErrors) {
          dispatch({ type: START_EVAL_SESSION_WITH_ERRORS });
          return;
        }
        if (isSkippingStartErrors) {
          dispatch({ type: CLEAR_EVAL_SESSION_STATE });
        }
      }
      startSession({ id: appointment.id });
    }
  }, [
    dispatch,
    insurance,
    appointment,
    startSession,
    isMissingRequiredDocs,
    isMissingDocsNoticesEnabled,
    isSkippingStartErrors,
    hasAskedForHomeTreatment,
    isHomeHealthAlertEnabled,
  ]);

  const goToCarePlan = useCallback(
    () =>
      history.push(getCarePlanPath({ patientId: String(patient?.id) || '' })),
    [history, patient]
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = useCallback(() => setIsMenuOpen(!isMenuOpen), [
    setIsMenuOpen,
    isMenuOpen,
  ]);

  return (
    <>
      <Flex flexDirection="column" width="100%">
        <HeaderBar
          title={<AppointmentDate appointment={appointment} />}
          left={<ArrowBackIcon size={20} onClick={goBack} />}
          right={
            <DropdownMenu
              isOpen={isMenuOpen}
              position={DropdownPosition.BottomLeft}
              trigger={<MenuIcon size={20} onClick={toggleMenu} />}
              onClose={toggleMenu}
              menuItems={[
                ...(appointment
                  ? [
                      {
                        id: 'change-type',
                        content: 'Change appointment type',
                        to: getAppointmentUpdateUrl({
                          appointmentId: String(appointment.id),
                        }),
                      },
                      ...(isAppointmentRescheduleEnabled
                        ? [
                            {
                              id: 'reschedule',
                              content: 'Reschedule',
                              to: getReschedulePath({
                                appointmentId: appointment.id,
                              }),
                            },
                          ]
                        : []),
                    ]
                  : []),
              ]}
            />
          }
        />
        {appointment && patient && (
          <>
            <ScrollBox flexDirection="column">
              {(patient?.lat && patient?.lng && (
                <Map
                  height={210}
                  initialCenter={{ lat: patient.lat, lng: patient.lng }}
                >
                  <Marker />
                </Map>
              )) ||
                null}
              <Flex backgroundColor="muted" padding={2} alignItems="center">
                <MapPinIcon size={30} />
                <Flex flexDirection="column">
                  <Flex>{patient?.address}</Flex>
                  <Flex>
                    {patient?.city}, {patient?.state} {patient?.zipCode}
                  </Flex>
                </Flex>
                <Flex flex={1} />
                <Text
                  as="a"
                  href={getGoogleMapsDirectionsUrl({
                    lat: patient?.lat,
                    lng: patient?.lng,
                  })}
                  variant="link"
                >
                  Google
                </Text>
              </Flex>
              <Flex flexDirection="column" padding={3} width="100%">
                <Flex
                  justifyContent="space-between"
                  marginBottom={2}
                  width="100%"
                >
                  <PatientInfo
                    firstName={patient.firstName}
                    lastName={patient.lastName}
                    service={appointment.service}
                  />
                  <AppointmentDurationLocationInfo appointment={appointment} />
                </Flex>
                <Flex
                  width="100%"
                  justifyContent="space-between"
                  marginBottom={3}
                >
                  <FieldDisplayer title="Insurance" value={patient.insurance} />
                  <FieldDisplayer
                    title="Session"
                    value={
                      therapyCase
                        ? getAppointmentCaseProgressionFraction({
                            therapyCase,
                            appointment,
                          })
                        : 'N/A'
                    }
                  />
                  <Flex>
                    <CallButton number={patient?.phone} size={35} />
                  </Flex>
                </Flex>
                <Box marginBottom={3}>
                  <FieldDisplayer
                    title="Charge copay and/or cancellation fee"
                    value={
                      <Flex>
                        <Text fontWeight="bold" paddingRight={2}>
                          {formatCurrency({
                            amount: appointment.cancellationFee || 0,
                          })}
                        </Text>
                        <CustomTextLink
                          to={getCancellationPath({
                            appointmentId: appointment.id,
                          })}
                        >
                          Cancel appointment
                        </CustomTextLink>
                      </Flex>
                    }
                  />
                </Box>
                <Button variant="secondary" onClick={goToCarePlan}>
                  Patient Plan
                </Button>
              </Flex>
              <ConnectedAppointmentInfo appointment={appointment} />
              <Flex flex={1} />
            </ScrollBox>
            <div style={{ position: 'relative' }}>
              <MissingDocumentHandler
                appointment={appointment}
                isStartSessionDisabled={isStartSessionDisabled}
                isSkippingStartErrors={isSkippingStartErrors}
                lead={lead}
                patient={patient}
                therapyCase={therapyCase}
              />
              <AppointmentArrivalButton
                onClick={startThisSession}
                disabled={isStartSessionDisabled}
                loading={isFetchingSessionData}
                label="Start Session"
                startTime={startTime}
              >
                <CallError fetchStatus={fetchStatus} />
              </AppointmentArrivalButton>
            </div>
          </>
        )}
        {!appointment && (
          <Flex padding={3}>
            Appointment not found
            <Text
              onClick={goBack}
              variant="link"
              paddingLeft={1}
              paddingRight={1}
            >
              go back
            </Text>
          </Flex>
        )}
      </Flex>
      {showHomeTreatmentAlert ? (
        <RisePopup
          onClickOverlay={() => {
            setHasAskedForHomeTreatment(false);
            setShowHomeTreatmentAlert(false);
          }}
          sx={{
            height: '100vh',
            position: 'fixed',
            left: 0,
            bottom: 0,
            zIndex: 9999,
          }}
          render={({ createHideHandler }) => {
            return (
              <HomeHealthWarningCheck
                onCancelVisit={createHideHandler(() => {
                  dispatch({
                    type: HOME_HEALTH_APPOINTMENT_CANCEL,
                    payload: { appointmentId: appointment?.id },
                  });
                  setShowHomeTreatmentAlert(false);
                  setHasAskedForHomeTreatment(false);
                })}
                onProceedWithVisit={createHideHandler(() => {
                  appointment && startThisSession();
                  setShowHomeTreatmentAlert(false);
                })}
              />
            );
          }}
        />
      ) : null}
    </>
  );
};

export const ScheduledAppointmentPage = connect(
  null,
  mapDispatchToProps
)(ScheduledAppointmentPageComponent);
