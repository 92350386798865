import {
  dischargeCase,
  fetchCaseAppTypes,
  fetchPatientCases,
} from 'modules/cases/actions';
import { updateAppointment } from 'modules/appointments/actions';
import { getCaseFetchStatus } from 'selectors/cases/getCaseFetchStatus';
import { createActionHook } from 'utils/hooks/useCall/createActionHook';
import { startSession } from 'modules/appointments/actions';
import { getAppointmentsFetchStatus } from 'selectors/appointments/getAppointmentsFetchStatus';
import { claimOffer, fetchOffer, unclaimOffer } from 'modules/offers/actions';
import { getOfferFetchStatus } from 'selectors/offers/getOfferFetchStatus';
import { getOfferClaimFetchStatus } from 'selectors/offers/getOfferClaimFetchStatus';
import { fetchAppFile } from 'modules/files/actions';
import { getFileFetchStatus } from 'selectors/files/getFileFetchStatus';
import { getCaseAppTypesFetchStatus } from 'selectors/cases/getCaseAppTypesFetchStatus';

export enum CallAction {
  Discharge = 'Discharge',
  StartSession = 'StartSession',
  FetchOffer = 'FetchOffer',
  ClaimOffer = 'ClaimOffer',
  UnclaimOffer = 'UnclaimOffer',
  FetchPatientCases = 'FetchPatientCases',
  FetchFile = 'FetchFile',
  FetchCaseAppTypes = 'FetchCaseAppTypes',
  UpdateAppointment = 'UpdateAppointment',
}

export const ACTION_HOOKS = {
  // Appointments
  [CallAction.UpdateAppointment]: createActionHook({
    actionCreator: updateAppointment,
    fetchStatusSelector: getAppointmentsFetchStatus,
  }),

  // Cases
  [CallAction.FetchPatientCases]: createActionHook({
    actionCreator: fetchPatientCases,
    fetchStatusSelector: getCaseFetchStatus,
  }),

  [CallAction.Discharge]: createActionHook({
    actionCreator: dischargeCase,
    fetchStatusSelector: getCaseFetchStatus,
  }),
  [CallAction.StartSession]: createActionHook({
    actionCreator: startSession,
    fetchStatusSelector: getAppointmentsFetchStatus,
  }),
  [CallAction.FetchCaseAppTypes]: createActionHook({
    actionCreator: fetchCaseAppTypes,
    fetchStatusSelector: getCaseAppTypesFetchStatus,
  }),

  // Offers
  [CallAction.FetchOffer]: createActionHook({
    actionCreator: fetchOffer,
    fetchStatusSelector: getOfferFetchStatus,
  }),
  [CallAction.ClaimOffer]: createActionHook({
    actionCreator: claimOffer,
    fetchStatusSelector: getOfferClaimFetchStatus,
  }),
  [CallAction.UnclaimOffer]: createActionHook({
    actionCreator: unclaimOffer,
    fetchStatusSelector: getOfferClaimFetchStatus,
  }),

  // Files
  [CallAction.FetchFile]: createActionHook({
    actionCreator: fetchAppFile,
    fetchStatusSelector: getFileFetchStatus,
  }),
};
