import React, { useCallback } from 'react';
import { Formik, FormikProps } from 'formik';
import { AppointmentUpdatePageForm } from './AppointmentUpdatePageForm';
import { useRouteMatch } from 'react-router-dom';
import { AppointmentReschedulePathParams } from 'routing/routePaths';
import { useSelectorWithProps } from 'utils/hooks/useSelectorWithProps';
import { getAppointment } from 'selectors/appointments/getAppointment';
import { getCase } from 'selectors/cases/getCase';
import { HeaderBar } from 'components/HeaderBar';
import { CloseIcon } from 'components/icons/CloseIcon';
import { Flex } from 'rebass';
import { useGoBack } from 'utils/hooks/useGoBack';
import { ACTION_HOOKS, CallAction } from 'utils/hooks/useCall/config';
import { FetchStatus } from 'types/types';
import toast from 'react-hot-toast';

export interface FormValues {
  appointmentType: any;
}

const initialValues: FormValues = {
  appointmentType: null,
};

export const AppointmentUpdatePage = () => {
  const goBack = useGoBack();
  const {
    params: { appointmentId },
  } = useRouteMatch<AppointmentReschedulePathParams>();
  const appointment = useSelectorWithProps(getAppointment, {
    appointmentId,
  });
  const { caseId } = appointment || { caseId: '' };
  const therapyCase = useSelectorWithProps(getCase, {
    caseId: String(caseId),
  });

  const { call, fetchStatus } = ACTION_HOOKS[CallAction.UpdateAppointment]({
    onSuccess: () => {
      toast.success('Success');
      goBack();
    },
  });

  const onSubmit = useCallback(
    values => {
      const newType = values.appointmentServiceType?.name;
      if (newType) {
        call({
          id: Number(appointmentId),
          appType: newType,
        });
      }
    },
    [call, appointmentId]
  );

  const renderForm = useCallback(
    ({ submitForm }: FormikProps<FormValues>) => {
      if (!therapyCase) {
        return null;
      }
      return (
        <AppointmentUpdatePageForm
          onSubmit={submitForm}
          caseId={therapyCase.id}
          isLoading={fetchStatus === FetchStatus.Fetching}
        />
      );
    },
    [therapyCase, fetchStatus]
  );

  const renderContent = useCallback(() => {
    if (!appointment) {
      return (
        <Flex p={2}>Error: no appointment found with id {appointmentId}</Flex>
      );
    }
    if (!therapyCase) {
      return <Flex p={2}>Error: no case found with id {caseId}</Flex>;
    }
    return (
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {renderForm}
      </Formik>
    );
  }, [appointment, therapyCase, onSubmit, renderForm, appointmentId, caseId]);

  return (
    <Flex flexDirection="column" width="100%">
      <HeaderBar
        title="Update Appointment"
        right={<CloseIcon size={20} onClick={goBack} />}
      />
      {renderContent()}
    </Flex>
  );
};
