import React, { ReactNode } from 'react';
import { Dropdown, Props as DropdownProps } from 'components/Dropdown/Dropdown';
import { Flex } from 'rebass';
import { CustomLink, To } from 'components/CustomLink/CustomLink';

interface MenuItem {
  id: string;
  content: ReactNode;
  onClick?: () => void;
  onClose?: () => void;
  to?: To;
}

interface Props extends Omit<DropdownProps, 'children'> {
  menuItems: MenuItem[];
}

export const DropdownMenu = ({
  isOpen,
  trigger,
  menuItems,
  position,
  onClose = () => {},
}: Props) => {
  return (
    <Dropdown
      isOpen={isOpen}
      trigger={trigger}
      position={position}
      onClose={onClose}
    >
      <Flex flexDirection="column" width="max-content">
        {menuItems.map(({ id, content, onClick = () => {}, to }, index) => (
          <Flex
            key={id}
            onClick={onClick}
            sx={
              index !== 0
                ? {
                    borderTop: '1px solid',
                    borderColor: 'muted',
                  }
                : {}
            }
          >
            <Flex p={2}>
              {to ? <CustomLink to={to}>{content}</CustomLink> : content}
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Dropdown>
  );
};
