import React, { SyntheticEvent, useEffect } from 'react';
import { Box, Flex, Text } from 'rebass';
import { ScrollBox } from 'components/ScrollBox/ScrollBox';
import { FooterButton } from 'components/FooterButton/FooterButton';
import { useField } from 'formik';
import {
  AppointmentServiceTypeSelector,
  fieldName,
} from 'pages/booking/AppointmentServiceTypeSelector';
import { ACTION_HOOKS, CallAction } from 'utils/hooks/useCall/config';
import { isFetching } from 'utils/fetchStatus';
import { Loader } from 'components/Loader/Loader';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';

interface Props {
  onSubmit: (e: SyntheticEvent) => void;
  caseId: number;
  isLoading: boolean;
}

export const AppointmentUpdatePageForm = ({
  onSubmit,
  caseId,
  isLoading,
}: Props) => {
  const [{ value: fieldValue }] = useField(fieldName);
  const {
    call: callFetchCaseAppTypes,
    fetchStatus: fetchCaseAppTypesFetchStatus,
  } = ACTION_HOOKS[CallAction.FetchCaseAppTypes]({});

  useEffect(() => {
    callFetchCaseAppTypes(caseId);
  }, [caseId, callFetchCaseAppTypes]);

  const isLoadingCaseAppTypes = isFetching(fetchCaseAppTypesFetchStatus);

  const finalLabel = (
    <Flex justifyContent="center" alignItems="center">
      {isLoading ? (
        <Box marginLeft={1}>
          <LoadingSpinner size={20} spinnerColor="white" />
        </Box>
      ) : (
        <Text>Update</Text>
      )}
    </Flex>
  );

  return (
    <>
      <ScrollBox flexDirection="column" flex={1} padding={2}>
        {caseId ? (
          <Loader size={30} isLoading={isLoadingCaseAppTypes}>
            <Flex flexDirection="column" padding={3}>
              <Text marginBottom={1}>Appointment type:</Text>
              <AppointmentServiceTypeSelector selectedCaseId={caseId} />
            </Flex>
          </Loader>
        ) : null}
      </ScrollBox>
      <FooterButton
        label={finalLabel}
        onClick={onSubmit}
        disabled={!fieldValue || isLoading}
      />
    </>
  );
};
